import classNames from "classnames";
import * as classes from "./Container.css";

export function Container({
  children,
  shiftUp,
  header,
  footer,
}: {
  children: React.ReactNode;
  /** Percentage to shift the container up */
  shiftUp?: number;
  header?: React.ReactNode;
  footer?: React.ReactNode;
}) {
  return (
    <section
      className={classes.container}
      style={{ transform: shiftUp ? `translateY(-${shiftUp}%)` : undefined }}
    >
      <div className={classes.scrollArea}>
        <div
          className={classNames(classes.content, {
            [classes.noFooter]: !footer,
          })}
        >
          {header}
          {children}
        </div>
      </div>
      {footer && <div className={classes.footer}>{footer}</div>}
    </section>
  );
}
